






























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseButton extends Vue {
  @Prop() private status!: string;
}
  
